<template>
  <div>
    <form-base
      v-model="customer"
      @update-form-status="updateFormBaseStatus"
    />
    <form-detail
      v-model="customer"
      @update-form-status="updateFormDetailStatus"
    />

    <attachments
      ref="attachments"
      v-model="customer"
      @update-files="val => customer.document_files = val"
      @all-updated="handleAllUpdated"
    />

    <actions
      :buttons="actionsButton"
      @click="handleActionsButton"
    />
  </div>
</template>

<script>
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import customerStoreModule from '@/views/customer-hotel/customerStoreModule'
import useCustomerCreate from '@/views/customer-hotel/create/useCustomerCreate'
import FormBase from '@/views/customer-hotel/create/FormBase.vue'
import FormDetail from '@/views/customer-hotel/create/FormDetail.vue'
import Actions from '@core/components/fixed-button/Fixed-Right-Button.vue'
import Attachments from '@/views/components/util/AttachmentsCreate.vue'

export default {
  components: {
    FormBase,
    FormDetail,
    Attachments,
    Actions,
  },
  computed: {
    actionsButton() {
      const validActions = []
      validActions.push(this.ACTIONS_BUTTON.UPDATE)
      return validActions
    },
  },
  created() {
    const { id } = this.$route.params
    this.$call(this.fetchCustomer(id, res => {
      Object.assign(this.customer, res.data)
      this.customer.district_ids = this.customer.districts.map(item => item.id)
      this.customer.street_ids = this.customer.streets.map(item => item.id)
    }))
  },
  methods: {
    updateFormBaseStatus(statusMsg) {
      this.formBaseStatus = statusMsg
    },
    updateFormDetailStatus(statusMsg) {
      this.formDetailStatus = statusMsg
    },
    handleAllUpdated(files) {
      if (!this.isUploading) return
      this.customer.document_files = files
      this.handleCreateCustomer()
    },
    handleActionsButton(btn) {
      if (btn.value === 'save') {
        const pending = this.$refs.attachments.$refs.uploads.$refs.uploads.getQueuedFiles()
        if (pending && pending.length) {
          this.isUploading = true
          this.$refs.attachments.$refs.uploads.$refs.uploads.processQueue()
        } else {
          this.handleUpdateCustomer()
        }
      }
    },
    handleUpdateCustomer() {
      this.$call(this.updateCustomer(this.customer), true).then(({ id }) => {
        this.$router.push({ name: `customer-${this.customerMenuType}-detail`, params: { id } })
      })
    },
    handleCreateCustomer() {
      if (this.formBaseStatus === undefined || this.formBaseStatus.length) {
        this.$showNoti('Cần kiểm tra địa chỉ trước khi tạo', 'warning')
        return
      }
      if (!this.customer.title || !this.customer.content) {
        this.$showNoti('Cần cập nhật chi tiết trước khi tạo', 'warning')
        return
      }
      this.$call(this.createCustomer(this.customer), true).then(({ id }) => {
        this.$router.push({ name: `customer-${this.customerMenuType}-detail`, params: { id } })
      })
    },
    getFormalityCustomer() {
      return []
    },
  },
  setup() {
    const CUSTOMER_STORE_MODULE_NAME = 'customer'
    if (!store.hasModule(CUSTOMER_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_STORE_MODULE_NAME, customerStoreModule)
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_STORE_MODULE_NAME)
    })
    const formBaseStatus = ref(undefined)
    const formDetailStatus = ref(undefined)
    return {
      formBaseStatus,
      formDetailStatus,
      ...useCustomerCreate(),
    }
  },
}
</script>

<style lang="scss">
</style>
